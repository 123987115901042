<template>
  <EdgeStack :id="esId" :busy="busy">
    <template v-slot:header>
      <div class="pb-3 panel-title">{{ getTitleText }}</div>

      <XStepper
        :id="esId"
        :steps="stepperSteps"
        :mode="stepperMode"
        v-model="stepperCurrentStep"
        ref="xstepper"
      />
    </template>

    <template v-slot:footer>
      <XStepperNavigation
        v-if="isEditing"
        :id="esId"
        :steps="stepperSteps"
        :submit-button-text="getSubmitButtonText"
        @submit="onSubmit"
        v-model="stepperCurrentStep"
      />

      <AppButton
        v-if="!isEditing"
        :text="getSubmitButtonText"
        @click="onSubmit({ step: stepperCurrentStep })"
      />
    </template>

    <template #default>
      <keep-alive>
        <template v-if="stepperCurrentStep === 1">
          <VehicleAddEditStep1
            ref="step1"
            :selected-lock-id="selectedLockId"
            :lock-types="lockTypes"
            :unassigned-locks="unassignedLocks"
            :lock-manufacturers="lockManufacturers"
            :primary-key="primaryKeyProxy"
            :primary-key-lock="primaryKeyLockProxy"
            :form-data="getStep1FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
          />
        </template>

        <template v-else-if="stepperCurrentStep === 2">
          <VehicleAddEditStep2
            ref="step2"
            :fleets="fleets"
            :vehicle-types="vehicleTypes"
            :selected-lock-id="selectedLockId"
            :vehicle-manufacturers="vehicleManufacturers"
            :vehicleModels="vehicleModels"
            :primary-key="primaryKeyProxy"
            :primary-key-lock="primaryKeyLockProxy"
            :form-data="getStep2FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
          />
        </template>
      </keep-alive>
    </template>
  </EdgeStack>
</template>

<script>
import pick from 'lodash/pick'

// import { useEndpoints } from '@/composables'

import { AppButton } from '@/components/form'
import { EdgeStack } from '@/components/modals'
import { XStepper, XStepperNavigation } from '@/components/stepper'

import VehicleAddEditStep1 from './VehicleAddEditStep1'
import VehicleAddEditStep2 from './VehicleAddEditStep2'

import { getVehicleFormModel } from './index'

import { useEndpoints } from '@/composables'
import { VehicleModelConfig } from '@/config/VehicleModelConfig'

export default {
  name: 'VehicleAddEdit',

  components: {
    EdgeStack,
    XStepper,
    XStepperNavigation,
    AppButton,
    VehicleAddEditStep1,
    VehicleAddEditStep2,
  },

  props: {
    esId: {
      type: String,
      default: 'vehicle-add-edit',
    },
    stepperMode: {
      type: String,
      default: 'free',
    },
    stepperStep: {
      type: Number,
      default: 1,
    },
    // vehicle.id
    primaryKey: {
      required: false,
    },
    // vehicle.lock.id
    primaryKeyLock: {
      required: false,
    },
    rawData: {
      type: Object,
      required: false,
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      errors: [],
      // Unless a step is free, user can't navigate to that step by clicking on the step
      // todo: also support this feature on xStepperNavigation & xStepper.navigate() helpers

      stepperSteps: [
        {
          title: this.$t('components.vehicleManagement.addEdit.tabs.iot'),
          free: true,
        },
        {
          title: this.$t('components.vehicleManagement.addEdit.tabs.vehicle'),
          free: false,
        },
      ],
      // this is the proxy of prop.stepperStep. It's used as v-model for steps
      // it needs to be in sync -> watched
      stepperCurrentStep: 1,

      // following data will be collected from step 1 & required for both steps
      selectedLockId: '', // <-- id: [uuid]

      // following data are required for the stepper steps
      fleets: [],
      lockTypes: [],
      vehicleTypes: [],
      unassignedLocks: [],
      lockManufacturers: [],
      vehicleManufacturers: [],
      vehicleModels: [],

      primaryKeyProxy: null,
      primaryKeyLockProxy: null,
    }
  },

  computed: {
    // User data is meant to be passed to be in edit mode
    // ? is it better to also check stepperMode? i'll think about it later
    // todo: don't allow navigating to next step in editing mode if current step is dirty
    // todo: -> need to save dirty state with step data, pass prob allowNext & allowPrev on XStepperNavigation component
    isEditing() {
      return !!this.rawData
    },

    getTitleText() {
      return this.isEditing
        ? this.$t('components.vehicleManagement.addEdit.headline.update')
        : this.$t('components.vehicleManagement.addEdit.headline.add')
    },

    getSubmitButtonText() {
      if (this.stepperCurrentStep > this.stepperSteps.length - 1)
        return this.$t('components.stepNavigation.finish')

      return this.isEditing
        ? this.$t('components.stepNavigation.update')
        : this.$t('components.stepNavigation.saveAndContinue')
    },

    // get steps formData prop getters : either valid object or null.
    // generate from prop.userData for setting edit mode data.
    //
    // pass the computed formData to all steps in all mode & watch prop.formData in step component
    // then set $data.form if props.formData are valid object (not null)
    //
    // the returned object must resemble as the $data.form object defined in different steps
    // it'll be watched & synced in the stepX component.
    getStep1FormData() {
      if (this.rawData) {
        const modelKeys = Object.keys(
          getVehicleFormModel({ step: 1, mode: 'edit' })
        )
        const lock = this.rawData.lock
        const picked = pick(lock, modelKeys)
        picked.start_date = lock?.start_date?.slice(0, 10) || null
        picked.manufacturer = lock.manufacturer?.id || null
        if (!picked.description || picked.description === 'null') {
          delete picked.description
        }

        console.log(picked, 'step1')
        return picked
      }

      return null
    },

    getStep2FormData() {
      if (this.rawData) {
        const modelKeys = Object.keys(
          getVehicleFormModel({ step: 2, mode: 'edit' })
        )
        const picked = pick(this.rawData, modelKeys)

        // override or add as per requirements
        picked.lock = this.rawData.lock?.id || null
        picked.fleet = this.rawData.fleet?.id || null
        picked.manufacturer = this.rawData.manufacturer?.id || null
        if (!picked.description || picked.description === 'null') {
          delete picked.description
        }
        console.log(picked, 'step2')

        return picked
      }

      return null
    },
  },

  async created() {
    await this.fetchData({ onlyUnassignedLocks: false })
  },

  mounted() {
    // reset current step to 1 on closed
    this.$edgeStack.emitter.on(
      this.$edgeStack.getEventName('closed', this.esId),
      () => {
        this.stepperCurrentStep = 1
      }
    )
  },

  beforeDestroy() {
    this.$edgeStack.emitter.off(
      this.$edgeStack.getEventName('closed', this.esId)
    )
  },

  watch: {
    primaryKey: {
      deep: false,
      immediate: true,
      handler(updatedId) {
        // primaryKey -> updatedId could be null (in add mode)
        if (updatedId) {
          this.primaryKeyProxy = updatedId
        }
      },
    },

    // it's used as a proxy var as step 1 need to be able to update it from save event (via onSave)
    // It's required for all steps of edit mode & even in add mode (except step 1 add mode)
    //
    // It's also updated from the save event listener of step 1 via onSave().
    // No need to listen on other step's save event as it should be the same id.
    primaryKeyLock: {
      deep: false,
      immediate: true,
      handler(updatedId) {
        // primaryKeyLock -> updatedId could be null (in add mode)
        if (updatedId) {
          this.primaryKeyLockProxy = updatedId
        }
      },
    },

    stepperStep: {
      deep: false,
      immediate: true,
      handler(updatedStep) {
        this.stepperCurrentStep = updatedStep
        console.log('st', this.stepperCurrentStep)
      },
    },
  },

  methods: {
    onDirty(type, id = this.esId) {
      return type === true
        ? this.$edgeStack.shouldConfirm(id)
        : this.$edgeStack.shouldNotConfirm(id)
    },

    async onSave(e = { step: null, data: {} }) {
      console.log(e)
      if (e.step === 1) {
        // if it's in add mode, user shouldn't be able to re submit step 1
        // or if the api supports it, it needs to be a patch request

        // if primaryKeyProxy is already set & mode is strict -> it means user is trying
        // to get back to step 1 & resubmit

        // // NOTE: handle re-submission of step 1 by checking primaryKey || isEditing
        // NOTE: in case of locks, it should be selectedLockId || isEditing

        // NOTE: in case of locks, selectedLockId is the primary key of step 1
        // // update primaryKeyProxy
        // // this.primaryKeyProxy = e.data.id

        // save selectedLockId
        this.selectedLockId = e.data.id
        this.primaryKeyLockProxy = e.data.id

        // check uniqueness & push to unassignedLocks array
        if (
          e.from !== 'chooseLock' &&
          this.unassignedLocks.indexOf((uLock) => uLock.id === e.data.id) === -1
        ) {
          this.unassignedLocks.push(e.data)
        }

        // free all steps
        const steps = [
          {
            title: this.$t('components.vehicleManagement.addEdit.tabs.iot'),
            free: true,
          },
          {
            title: this.$t('components.vehicleManagement.addEdit.tabs.vehicle'),
            free: true,
          },
        ]

        this.$xStepper.defineSteps(this.esId, steps)
        this.beforeNextStep({ to: 2 })
      }

      if (e.step === 2) {
        // free only first step -> as we'r about to reset & close -> set it to initial state
        this.$xStepper.defineSteps(this.esId, this.stepperSteps)
        this.onLastStep()

        // reload unassigned fleets to sync with the server
        await this.fetchData({ onlyUnassignedLocks: true })
      }
    },

    beforeNextStep({ to = 2 }) {
      // define what happens about confirmation before exiting on next step (2)
      // on edit mode->after updating->should not confirm unless event triggered by user
      // on add mode->after saving->should confirm
      if (this.stepperMode === 'free') {
        this.$edgeStack.shouldNotConfirm(this.esId)
      } else {
        this.$edgeStack.shouldConfirm(this.esId)
      }

      if (to) {
        this.$xStepper.navigate(this.esId).to(to)
      } else {
        this.$xStepper.navigate(this.esId).next()
      }
    },

    onLastStep() {
      // notify that the table view needs to be updated
      this.$emit('refresh')

      // Should not confirm as we'r about to close it
      this.$edgeStack.shouldNotConfirm(this.esId)
      this.$edgeStack.close(this.esId)

      this.$edgeStack.emitter.on(
        this.$edgeStack.getEventName('closed', this.esId),
        () => {
          this.stepperCurrentStep = 1
        }
      )
    },

    onSubmit(step) {
      this.$refs[`step${step.step}`].submit()
    },

    async fetchData({ onlyUnassignedLocks = false }) {
      // specially unassignedLocks might need to be reloaded after editing / adding vehicle
      this.unassignedLocks = await this.$http
        .get(useEndpoints.dropdown.unassignedLocks())
        .then((res) => res.data.data)
        .catch((err) => console.log('unassignedLocksErr', err.response))

      if (onlyUnassignedLocks) return

      this.fleets = await this.$http
        .get(useEndpoints.dropdown.fleets())
        .then((res) => res.data.data)
        .catch((err) => console.log('fleetsErr = ', err.response))

      this.lockTypes = await this.$http
        .get(useEndpoints.dropdown.lockTypes())
        .then((res) => res.data.data)
        .catch((err) => console.log('lockTypesErr = ', err.response))

      this.lockManufacturers = await this.$http
        .get(useEndpoints.dropdown.lockManufacturers())
        .then((res) => res.data.data)
        .catch((err) => console.log('lockManufacturersErr = ', err.response))

      this.vehicleManufacturers = await this.$http
        .get(useEndpoints.dropdown.vehicleManufacturers())
        .then((res) => res.data.data)
        .catch((err) => console.log('vehicleManufacturersErr = ', err.response))

      this.vehicleModels = await this.$http
        .get(VehicleModelConfig.api.org.index(this.$org.id))
        .then((res) => res.data.data)
        .catch((err) => console.log('vehicleModelsErr = ', err.response))

      // set vehicle type dropdown based on subscription
      const vehicleTypeMap = {
        'SCOOTER': {
          text: 'Scooter',
          value: 'P',
        },
        'SCOOTER PRO': {
          text: 'Scooter Pro',
          value: 'PP',
        },
        'EBIKE': {
          text: 'E-Bike',
          value: 'E',
        },
        'BIKE': {
          text: 'Bike',
          value: 'S',
        },
        'COCO': {
          text: 'SuperCoco',
          value: 'C',
        },
        'KENOTA': {
          text: 'Kenota',
          value: 'K',
        },
        'MOPED': {
          text: 'Moped',
          value: 'M',
        },
      }
      const orgVehicleTypes = this?.$org?.vehicle_type || []
      orgVehicleTypes.forEach((t) => this.vehicleTypes.push(vehicleTypeMap[t]))
    },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
  margin-top: -30px;
  margin-bottom: 6px;
}
</style>
